
import { defineComponent, ref, onMounted } from "vue";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { useRouter } from "vue-router";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { mask } from "vue-the-mask";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import { ElTable } from "element-plus";
import JwtService from "@/core/services/JwtService";
import { ActionsFi } from "@/store/enums/StoreFiEnums";
import moment from "moment";

export default defineComponent({
  name: "add-client-modal",
  directives: { mask },
  components: {},

  setup(props, { emit }) {
    const store = useStore();
    const router = useRouter();
    const formRef = ref<null | HTMLFormElement>(null);
    const loading = ref<boolean>(false);
    const supplier_data = ref<Array<Supplier>>([]);
    const consignee_branch_data = ref([]);
    const pc_data = ref([]);

    const multipleTableRef = ref<InstanceType<typeof ElTable>>();
    const multipleSelection = ref<Product[]>([]);
    const tableData = ref<Array<Product>>([]);
    const igst_yn = ref(1);
    const rules = ref<any>({});

    interface Product {
      pc_detail_id: string;
      purchase_contract_no: string;
      supplier_company: string;
      consignee_warehouese: string;
      category_name: string;
      product_name: string;
      payment_term: string;
      incoterm: string;
      delivery_term: string;
      order_qty: string;
      product_total_rate: string;
      product_total_amount: string;
      uom: string;
    }

    interface Supplier {
      company_id: number;
      company_name: string;
      company_name_search: string;
      company_name_alternative: string;
      company_nob_id: number;
      company_nob_name: string;
      company_group_type_id: number;
      company_group_type_name: number;
      company_industry_type_id: number;
      company_industry_type_name: string;
      company_business_type_id: number;
      company_business_type_name: string;
      company_roc_id: number;
      company_roc_name: string;
      company_doi: string;
      company_cin_llpin: string;
      company_registration_number: string;
      company_pan: string;
      company_website: string;
      company_verified_yes_no: number;
      company_trade_type_id: number;
      active: number;
    }


    const formData = ref({
      supplier_select: "",
      supplier_warehouse_select: "",
      pc_no_select: "",
      sjvd_date: new Date(),
    });

    onMounted(async () => {
      setCurrentPageBreadcrumbs("New Purchase Order", []);
    });

    const setCompanyData = async (data) => {
      var user = JSON.parse(JwtService.getToken());
      debugger;
      let product_list = multipleSelection.value.map((item) => {
        return {
          pc_detail_id: item.pc_detail_id,
        };
      });
      if (product_list.length <= 0) {
        console.log("validation failed");
        Swal.fire({
          text: "Please Select At Least One Product.",
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: "Ok, got it!",
          customClass: {
            confirmButton: "btn btn-primary",
          },
        });
        loading.value = false;
        return;
      }

     const requestData =  {
          user_id : user.user_id,
          po_date : moment(data.sjvd_date).format("YYYY-MM-DD"),
          supplier_company_id: data.supplier_select,
          supplier_company_branch_id: data.supplier_warehouse_select,
          product_details: product_list
      }

      console.log(requestData);
      try {
        await store.dispatch(ActionsFi.CUST_CREATE_PURCHASE_ORDER, requestData)
        .then(({ data, is_error, status, message }) => {
            loading.value = false;
            if (data?.is_success && status !== 500){   
              setTimeout(() => {
                Swal.fire({
                  html: ""+
                      data?.po_no+
                      "<br>Purchase order created successfully!",
                  icon: "success",
                  buttonsStyling: false,
                  confirmButtonText: "Ok, got it!",
                  customClass: {
                    confirmButton: "btn btn-primary",
                  },
                }).then(() => {
                  router.push({ name: "apps-purchase-order-listing" });
                });
              }, 2000);
            }else if(data == "" && is_error == false && status == 500){
              Swal.fire({
                  text: message,
                  icon: "error",
                  buttonsStyling: false,
                  confirmButtonText: "Ok, got it!",
                  customClass: {
                    confirmButton: "btn btn-primary",
                  },
                }).then(() => {
                  router.push({ name: "apps-purchase-order-listing" });
                });
          }else {
                Swal.fire({
                  text: "Sorry, looks like there are some errors detected, please try again.",
                  icon: "error",
                  buttonsStyling: false,
                  confirmButtonText: "Ok, got it!",
                  customClass: {
                    confirmButton: "btn btn-primary",
                  },
                })  
                return false;
              }
            })
        .catch(({ response }) => {
          loading.value = false;
          console.log(response);        
        });
      } catch (e) {
        loading.value = false;
        console.log(e);
      }
    };

    const submit = async () => {
      debugger;
      loading.value = true;
      rules.value = {
        supplier_select: [
          {
            required: true,
            message: "Supplier is required",
            trigger: "change",
          },
        ],
        supplier_warehouse_select: [
          {
            required: true,
            message: "Warehouse is required",
            trigger: "change",
          },
        ],
        pc_no_select: [
          {
            required: true,
            message: "PC no is required",
            trigger: "change",
          },
        ],
        sjvd_date: [
          {
            required: true,
            message: "Date is required",
            trigger: "change",
          },
        ],
      };

      if (!formRef.value) {
        loading.value = false;
        return;
      }

      setTimeout(() => {
        formRef?.value?.validate(async (valid) => {
          if (valid) {
            await setCompanyData(formData.value);
          } else {
            loading.value = false;
            return false;
          }
        });
      }, 300);
    };

    async function getCompanyList(query: string) {
      debugger;
      if (query.length < 3) {
        return;
    }

      let values = { search_term: query };
      await store
        .dispatch(Actions.CUST_GET_SUPPLIER_DROPDOWN_LIST, values)
        .then(({ data }) => {
          debugger;
          supplier_data.value = data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    }

    async function getBranchList() {
      let values = {
        company_id: formData.value["supplier_select"],
        company_branch_id: 0,
        page: 1,
        records_per_page: 1000,
      };
      await store
        .dispatch(Actions.CUST_GET_BRANCH_LIST, values)
        .then(({ data }) => {
          debugger;
          consignee_branch_data.value = data?.result_list;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    }

    async function getPCList() {
      let params = {
        supplier_company_id: formData.value["supplier_select"],
        supplier_company_branch_id: formData.value["supplier_warehouse_select"],
      };
      await store
        .dispatch(ActionsFi.CUST_GET_PNDING_PC_BY_CLIENT_ID, params)
        .then(({ data }) => {
          debugger;
          pc_data.value = data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    }

    const handleSelectionChange = (val: Product[]) => {
      multipleSelection.value = val;
    };

    const onCancle = () => {
      multipleTableRef.value!.clearSelection();
      multipleSelection.value = [];
      formData.value = {
        supplier_select: "",
        supplier_warehouse_select: "",
        pc_no_select: "",
        sjvd_date: new Date(),
      };
      formRef?.value?.resetFields();
      pc_data.value = [];
      tableData.value = []
    };

    async function getTableData() {
      let params = {
        pc_ids: formData.value["pc_no_select"],
      };
      debugger;
      await store
        .dispatch(ActionsFi.CUST_GET_PC_DETAILS_BY_IDS_DATA, params)
        .then(({ data }) => {
          debugger;
          data.map((product) => {
            product.order_qty = product.order_qty + " " + product.uom;
          });
          tableData.value = data;
          igst_yn.value = data[0].igst_yn
        })
        .catch(({ response }) => {
          console.log(response);
        });
    }

    return {
      formData,
      rules,
      submit,
      formRef,
      loading,
      supplier_data,
      getCompanyList,
      consignee_branch_data,
      getBranchList,
      tableData,
      multipleTableRef,
      multipleSelection,
      handleSelectionChange,
      onCancle,
      getTableData,
      pc_data,
      getPCList,
      igst_yn
    };
  },
});
